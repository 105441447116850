




















































































































































import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {
  },
})

export default class DashHeader extends Vue {
  private ui = vxm.ui;
  private auth = vxm.auth;
  private darkMode: boolean = false;

  private myVaultPath = process.env.VUE_APP_MYVAULT_API_URL;   

  private toggleDarkMode() {
    this.ui.toggleDarkMode();
    document.body.classList.toggle('c-dark-theme')
  }

  get name(){
    return this.auth.usr?.displayName;
  }

}


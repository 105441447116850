









import {vxm} from "@/store";
import {Component, Vue, Prop} from 'vue-property-decorator';


@Component
export default class Casebook extends Vue {

  private ui = vxm.ui;
  //note these dynamic static assets will likely be served from S3
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private navigate(){
      this.$router.push('/dashboard/casebook');
  }
}

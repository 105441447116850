
































import { nav } from './_nav'

declare interface dataInterface {
  nav: typeof nav
}
import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {
  },
})

export default class DashSidebar extends Vue {
  private ui = vxm.ui;
  private content = nav;
  get show(){
    return this.ui.sidebarShow;
  }
  get minimize() {
    return this.ui.sidebarMinimize;
  }

  private miniMeToggle(){
    this.ui.sidebarMinimize=!this.ui.sidebarMinimize;
  }

  private updateShow(value: any){
    this.ui.sidebarShow=value;
  }
}

// export default Vue.extend({
//   name: 'TheSidebar',
//   data (): dataInterface {
//     return {
//       nav
//     }
//   },
//   computed: {
//     show (): string | boolean {
//       return this.$store.state.sidebarShow
//     },
//     minimize (): boolean {
//       return this.$store.state.sidebarMinimize
//     }
//   }
// })

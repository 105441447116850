

























import TheFooter from '@/containers/TheFooter.vue'


import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';
import SearchAutoComplete from "@shared_vue/components/searchautocomplete/SearchAutoComplete.vue";
import {CaseApiFactory} from "@shared_vue/openapi/caseapi/api";
import {Configuration} from "@shared_vue/openapi/caseapi/configuration";
import {SelectOption} from "@shared_vue/types/SelectOption";


@Component({
    components: {
        SearchAutoComplete
    },
})

export default class DashAsideRight extends Vue {
  private timer: ReturnType<typeof setTimeout> | undefined;
  private ui = vxm.ui;
  private searchText = ''
  private waitForMe = false;
  private searchList : Array<SelectOption> = [];
  private caseApi = CaseApiFactory(<Configuration>{basePath:process.env.VUE_APP_CASE_API_URL},process.env.VUE_APP_CASE_API_URL)
  
  private navigateUser(whom: SelectOption){
    console.log('navigating to: ' + whom.value)
    this.$router.push('/dashboard/user/' + whom.value)
  }

  private clearMe(){
    this.waitForMe = false;
  }
  
  private waitABit(){
    let outerThis = this;
    this.waitForMe = true;
    this.timer = setTimeout(()=>{
      outerThis.clearMe();
    }, 200)
  }
  
  private async search(what: string){
    console.log('search ' + what)
    if (what.length<3){
      console.log('too short')
      return;
    }
    if (this.waitForMe){
      console.log('waiting')
      return;
    }
    this.waitABit();
    let searchRes = await this.caseApi.caseSearchPredict(what);
    if (searchRes.status==200){
      console.log(searchRes.data)
      this.searchList = searchRes.data.map((el)=>new SelectOption(el.firstNames! + ' ' + el.lastName!, el.guid!));
    }
  }
}

































import TheFooter from '@/containers/TheFooter.vue'


import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';
import DashHeader from "@/containers/DashHeader.vue";
import DashSidebar from "@/containers/DashSidebar.vue";
import DashAsideLeft from "@/containers/DashAsideLeft.vue";
import DashAsideRight from "@/containers/DashAsideRight.vue";

@Component({
  components: {
    DashAsideLeft,
    DashAsideRight,
    DashHeader,
    DashSidebar,
    TheFooter,
  },
})

export default class DashContainer extends Vue {
  private ui = vxm.ui;
}


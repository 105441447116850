

























import TheFooter from '@/containers/TheFooter.vue'
import TbButton from "@/views/TbButton.vue";

import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';
import Casebook from "@/views/widgets/Casebook.vue";

@Component({
    components: {
      Casebook,
      TbButton
    },
})

export default class DashAsideLeft extends Vue {
    private ui = vxm.ui;
    private isOnMobile () {
        return Boolean(getComputedStyle(this.$el).getPropertyValue('--is-mobile'))
    }
}

